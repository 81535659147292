import { BoltIcon, DevicePhoneMobileIcon, GlobeAltIcon, ScaleIcon, CircleStackIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline'

const background = require('./images/image4.jpg')

const OldHome = () => {

  const features = [
    {
      name: 'Authentication',
      description:
        "Pre-built authentication that scales with your web app. No need to worry about user onboarding.",
      icon: GlobeAltIcon,
    },
    {
      name: 'Payments',
      description:
        'Boilerplate Stripe integrations. Avoid the hassle of setting up payments.',
      icon: ScaleIcon,
    },
    {
      name: 'Templates',
      description:
        'Beautiful templates to get you started. Built specifically for generative AI web apps.',
      icon: BoltIcon,
    },
    {
      name: 'Hosting/Deployment',
      description:
        'Deploy your model and launch your projects instantly.',
      icon: DevicePhoneMobileIcon,
    },
    {
      name: 'Model Training',
      description:
        'Train your own custom models with our intuitive playground.',
      icon: CircleStackIcon,
    },
    {
      name: 'Dashboard',
      description:
        'Manage your web apps and custom models from a single dashboard.',
      icon: ComputerDesktopIcon,
    },
  ]
  

  return (
    <div className="isolate text-gray-200">      
      <main>
        <div className="relative px-6 lg:px-8 py-20 mb-24" style={{ 
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%'
    }}>
          <div className="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
            <div>
              <div>
                <p className="mt-6 text-xl leading-8 text-gray-200 sm:text-center px-5">
                    Aspen
                </p>
                <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                  Build AI-powered tools in minutes
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-200 sm:text-center">
                  Aspen is a low-code platform for building generative AI web apps. 
                </p>
                <div className="mt-4 flex gap-x-4 sm:justify-center">
                  <a
                    href="https://sj37228fgb1.typeform.com/to/jJOl7eYE"
                    className="inline-block rounded-lg bg-indigo-500 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-800"
                  >
                    Join the waitlist
                  </a>
                </div>
                <div className="mt-4 flex gap-x-4 sm:justify-center">
                  <a
                    href="https://www.youtube.com/watch?v=0cms4b7UqJg"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block rounded-lg underline px-4 py-1.5 text-base leading-7 text-white shadow-sm hover:bg-indigo-800"
                  >
                    Watch the demo
                  </a>
                </div>
                
              </div>
              <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="px-20 mx-auto py-20">
          <section className="text-gray-200">
            <h2 className="text-3xl font-bold mb-12 text-center">How does it work?</h2>

            <div className="flex flex-wrap items-center">
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-5/12 lg:mb-0 md:px-6">
                <div className="relative overflow-hidden bg-no-repeat bg-cover rounded-lg shadow-lg">
                  <img src={require('./images/image2.jpg')} alt="magical place" className="w-full" />
                    <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                      >
                      <div className="flex justify-center items-center h-full">
                        <div className="px-6 py-12 md:px-12 text-white text-center">
                          <h3 className="lg:text-2xl md:text-2xl sm:text-md uppercase font-bold mb-6">
                            Participate in the AI <u>revolution</u>
                          </h3>
                          <p >
                            Curious about what you could build? Here's <a className='underline' href='https://saasaitools.com/' target="_blank" rel="noreferrer">some inspiration</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-hidden bg-no-repeat bg-cover">
                      <div
                        className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-100 transition duration-300 ease-in-out"
                      ></div>
                    </div>
                </div>
              </div>

              <div className="grow-0 shrink-0 basis-auto w-full lg:w-7/12 md:px-6">
                <div className="flex mb-12">
                  <div className="shrink-0">
                    <div className="p-4 rounded-md shadow-lg bg-indigo-900" >
                      <span className="w-5 h-5 text-white text-xl ">
                        <p>#1</p>
                      </span>
                    </div>
                  </div>
                  <div className="grow ml-4">
                    <p className="font-bold mb-1">Build your MVP in our playground</p>
                    <p className="text-gray-200">
                      Build a prototype of your AI web app in our playground. Configure your model settings or use one of our presets.
                    </p>
                  </div>
                </div>
                
                <div className="flex mb-12">
                  <div className="shrink-0">
                    <div className="p-4 rounded-md shadow-lg bg-indigo-900" >
                      <span className="w-5 h-5 text-white text-xl ">
                        <p>#2</p>
                      </span>
                    </div>
                  </div>
                  <div className="grow ml-4">
                    <p className="font-bold mb-1">Choose input and output formats</p>
                    <p className="text-gray-200">
                      Most AI web apps require some type of user input. This may include text, images, audio, or more. Similarly, the format of the output depends on the type of the generative model.
                    </p>
                  </div>
                </div>
                <div className="flex mb-12">
                  <div className="shrink-0">
                    <div className="p-4 rounded-md shadow-lg bg-indigo-900" >
                      <span className="w-5 h-5 text-white text-xl ">
                        <p>#3</p>
                      </span>
                    </div>
                  </div>
                  <div className="grow ml-4">
                    <p className="font-bold mb-1">Choose your frontend</p>
                    <p className="text-gray-200">
                      Based on your input and output formats, Aspen will generate a boilerplate frontend for your app. You can also choose from a variety of templates.
                    </p>
                  </div>
                </div>
                <div className="flex mb-12">
                  <div className="shrink-0">
                    <div className="p-4 rounded-md shadow-lg bg-indigo-900" >
                      <span className="w-5 h-5 text-white text-xl ">
                        <p>#4</p>
                      </span>
                    </div>
                  </div>
                  <div className="grow ml-4">
                    <p className="font-bold mb-1">Add peripheral requirements</p>
                    <p className="text-gray-200">
                      This includes authentication, payments, databases, and more.
                    </p>
                  </div>
                </div>
                <div className="flex mb-12">
                  <div className="shrink-0">
                    <div className="p-4 rounded-md shadow-lg bg-indigo-900" >
                      <span className="w-5 h-5 text-white text-xl ">
                        <p>#5</p>
                      </span>
                    </div>
                  </div>
                  <div className="grow ml-4">
                    <p className="font-bold mb-1">Launch</p>
                    <p className="text-gray-200">
                      Deploy your app and start onboarding users.
                    </p>
                  </div>
                </div>
                <div className="flex mb-12">
                  <div className="grow ml-18">
                    <p className="text-gray-200 italic">
                      Pricing starts at $35 per month. Free tiers available.
                    </p>
                    <p className="text-gray-200 italic">
                      Launching early 2023.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>




        <div className="py-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="sm:text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Features</p>
            </div>

            <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
              <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                    <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-900 text-white sm:shrink-0">
                      <feature.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                    <div className="sm:min-w-0 sm:flex-1">
                      <p className="text-lg font-semibold leading-8 text-gray-200">{feature.name}</p>
                      <p className="mt-2 text-base leading-7 text-gray-200">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container my-24 px-6 mx-auto">

    <section className="">

      <div className="container mx-auto px-6 md:px-12 xl:px-32">
        <div className="text-center text-gray-200">
          <div className="block rounded-lg shadow-lg px-6 py-12 md:py-16 md:px-12">
            <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">Start building</h1>
            <a className="inline-block px-7 py-3 mb-2 md:mb-0 mr-0 md:mr-2 bg-indigo-900 text-white font-medium text-sm leading-snug rounded shadow-md hover:bg-indigo-800 hover:shadow-lg focus:bg-indigo-800 focus:shadow-lg focus:outline-none transition duration-150 ease-in-out" href="https://sj37228fgb1.typeform.com/to/jJOl7eYE" role="button">Join the waitlist</a>
          </div>
        </div>
      </div>
    </section>
  
</div>


      </main>
    </div>
  )
}

export default OldHome;